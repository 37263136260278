exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-policy-js": () => import("./../../../src/pages/accessibility-policy.js" /* webpackChunkName: "component---src-pages-accessibility-policy-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-case-study-detail-js": () => import("./../../../src/pages/case-study-detail.js" /* webpackChunkName: "component---src-pages-case-study-detail-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-creative-service-js": () => import("./../../../src/pages/creative-service.js" /* webpackChunkName: "component---src-pages-creative-service-js" */),
  "component---src-pages-development-service-js": () => import("./../../../src/pages/development-service.js" /* webpackChunkName: "component---src-pages-development-service-js" */),
  "component---src-pages-ecommerce-service-js": () => import("./../../../src/pages/ecommerce-service.js" /* webpackChunkName: "component---src-pages-ecommerce-service-js" */),
  "component---src-pages-hr-service-js": () => import("./../../../src/pages/hr-service.js" /* webpackChunkName: "component---src-pages-hr-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-service-js": () => import("./../../../src/pages/marketing-service.js" /* webpackChunkName: "component---src-pages-marketing-service-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pm-service-js": () => import("./../../../src/pages/pm-service.js" /* webpackChunkName: "component---src-pages-pm-service-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shopify-partner-js": () => import("./../../../src/pages/shopify-partner.js" /* webpackChunkName: "component---src-pages-shopify-partner-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-virtual-assistants-js": () => import("./../../../src/pages/virtual-assistants.js" /* webpackChunkName: "component---src-pages-virtual-assistants-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-js": () => import("./../../../src/templates/blog-tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-casestudy-post-js": () => import("./../../../src/templates/casestudy-post.js" /* webpackChunkName: "component---src-templates-casestudy-post-js" */),
  "component---src-templates-ecommerce-post-js": () => import("./../../../src/templates/ecommerce-post.js" /* webpackChunkName: "component---src-templates-ecommerce-post-js" */)
}

